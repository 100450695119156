<template>
  <v-container class="pa-0">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        v-for="w of roundWorkout"
        :key="w.id"
        class="d-block"
        style="position: relative"
      >
        <div
          class="summary white text-center"
          style="position: absolute; width: 100%; z-index: 1"
        >
          <h2 class="mb-0 pb-0 pt-2" style="line-height: 1em">
            {{ w.name }}
          </h2>
          <h2
            class="green--text"
            style="line-height: 1.2em; text-transform: uppercase"
          >
            {{ w.instructions }}
          </h2>
        </div>
        <div
          class="video text-center mt-6"
          style="height: 100%"
        >
          <video autoplay="autoplay" muted loop class="round-video">
            <source
              :src="w.videoUrl"
              type="video/mp4"
            />
          </video>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.round-video {
  max-height: 95vh; /* Adjusts the maximum height to 80% of the viewport */
  object-fit: cover;
}
</style>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import moment from "moment";
export default {
  name: "Round",
  data() {
    return {
      loading: true,
      roundWorkout: [],
    };
  },
  async created() {
    this.unsubscribe = await fb.db
      .collection("scheduledWorkouts")
      .doc(this.$route.query.date || moment().format("YYYY-MM-DD"))
      .onSnapshot(async (res) => {
        try {
          const data = res.data();
          this.roundWorkout = data.workout[this.$route.params.id];
          console.log(data.workout[this.$route.params.id]);
        } catch (error) {
          console.log(error.message);
          const res = await fb.db
            .collection("scheduledWorkouts")
            .doc("2024-05-21")
            .get();
          const data = res.data();
          this.roundWorkout = data.workout[this.$route.params.id];
          console.log(data.workout[this.$route.params.id]);
          console.log(error);
        }
      });
  },
  beforeDestroy() {
    console.log("here");
    this.unsubscribe();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
  },
};
</script>
