<template>
  <v-container fluid fill-height style="max-width: 1680px" class="pb-8">
    <v-row class="text-center" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          large
          indeterminate
          color="primary"
          :size="80"
          :width="5"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row wrap v-if="!loading">
      <v-col cols="12" class="headline"
        >Workout Builder
        <v-btn
          class="ml-3"
          text
          outlined
          small
          @click="randomizeFromPrevious"
          :loading="randomizeLoader"
          >randomize from previous</v-btn
        ></v-col
      >
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" sm="12" md="8">
        <v-card
          v-for="(ex, round) in workout"
          :key="round"
          class="mb-4 grey lighten-5"
        >
          <v-card-title class="pl-3 secondary white--text">
            <h4>
              <v-icon left color="white" style="margin-top: -5px"
                >notifications</v-icon
              >ROUND {{ round }}
            </h4>
          </v-card-title>
          <v-card-text class="">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="availableWorkouts[round]"
                    item-text="name"
                    item-value="id"
                    v-model="workout[round][0]"
                    return-object
                    label="Select Exercise"
                    @click="filterExercises(round)"
                    clearable
                    hide-details
                  >
                    <template v-slot:item="data">
                      <template>
                        <span class="d-inline-block">
                          {{ data.item.name }}
                          <v-chip
                            x-small
                            color="primary"
                            class="mr-2"
                            v-if="data.item.createDate"
                            >new</v-chip
                          >
                        </span>

                        <span
                          class="ml-2 caption red--text"
                          v-if="previouslyUsed.includes(data.item.id)"
                          >* Recently Used</span
                        ></template
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-if="workout[round][0]"
                    :items="duration"
                    v-model="workout[round][0].instructions"
                    label="Duration"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="availableWorkouts[round]"
                    item-text="name"
                    item-value="id"
                    v-model="workout[round][1]"
                    return-object
                    label="Select Exercise"
                    @click="filterExercises(round)"
                    clearable
                    hide-details
                  >
                    <template v-slot:item="data">
                      <template
                        ><span class="d-inline-block">
                          {{ data.item.name }}
                          <v-chip
                            x-small
                            color="primary"
                            class="mr-2"
                            v-if="data.item.createDate"
                            >new</v-chip
                          > </span
                        ><span
                          class="ml-2 caption red--text"
                          v-if="previouslyUsed.includes(data.item.id)"
                          >* Recently Used</span
                        ></template
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-if="workout[round][1]"
                    :items="duration"
                    v-model="workout[round][1].instructions"
                    label="Duration"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <h4>Select Date</h4>
        <v-select
          :items="requestDays"
          v-model="requestDay"
          label="Workout day"
          item-value="displayDate"
          item-text="displayDate"
          prepend-icon="event"
          return-object
          :disabled="editing"
        ></v-select>
        <p v-if="editing" class="red--text">
          * You are editing this workout and it will overwrite the current one.
        </p>
        <v-btn
          :disabled="!requestDay.formattedDate"
          large
          class="mt-6"
          color="primary"
          block
          @click="saveWorkout"
          ><span v-if="!editing">Save Workout</span
          ><span v-else>Update Workout</span></v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import fb from "@/main";
import { sortBy } from "lodash";
export default {
  name: "Exercises",
  data() {
    return {
      filteredExercises: [],
      requestDay: {},
      requestDays: [],
      loading: true,
      workout: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
      },
      availableWorkouts: null,
      duration: [
        "1 Rep",
        "2 Reps",
        "5 Reps",
        "5 Reps/Side",
        "6 Reps",
        "6 Reps/Side",
        "8 Reps",
        "8 Reps/Side",
        "10 Reps",
        "10 Reps/Side",
        "12 Reps",
        "12 Reps/Side",
        "15 Reps",
        "15 Reps/Side",
        "20 Reps",
        "25 Reps",
        "30 Reps",
        "30 Seconds",
        "1 Minute",
        "1 Minute 30 Seconds",
        "2 Minutes",
        "2 Minutes 30 Seconds",
        "100 Meters",
        "200 Meters",
        "Turf Length",
        "Turf Length",
        "2x Turf Length",
        "4x Turf Length",
        "5x Turf Length",
        "6x Turf Length",
        "10 count",
        "Last 30 Seconds",
        "Rest of Round",
        "Full Round",
      ],
      exercises: [],
      snack: false,
      snackColor: "",
      snackText: "",
      editing: false,
      previouslyUsed: [],
      randomizeLoader: false,
    };
  },
  async created() {
    const res = await fb.db.collection("exercises").get();
    res.forEach((ex) => {
      this.exercises.push({ id: ex.id, ...ex.data() });
    });
    const prevWorkouts = [];
    const resPrevWorkouts = await fb.db
      .collection("scheduledWorkouts")
      .orderBy("date", "desc")
      .limit(5)
      .get();
    resPrevWorkouts.forEach((pw) => {
      prevWorkouts.push(pw.data());
    });

    const prevExercises = [];
    prevWorkouts.forEach((pw) => {
      Object.keys(pw.workout).forEach((k) => {
        prevExercises.push(pw.workout[k].map((pwk) => pwk.id));
      });
    });
    this.previouslyUsed = [...new Set(prevExercises)].flat();
    const requestDays = [];
    this.editing = !!this.$route.query.date;
    if (this.editing) {
      this.loading = true;
      try {
        console.log("this.$route.query.date", this.$route.query.date);
        const res = await fb.db
          .collection("scheduledWorkouts")
          .doc(this.$route.query.date)
          .get();
        const data = { id: res.id, ...res.data() };
        console.log("data.id", data.id);
        const day = moment(data.id);

        requestDays.push({
          displayDate: day.format("MMM Do (dddd)"),
          formattedDate: day.format("YYYY-MM-DD"),
        });
        this.requestDays = requestDays;
        this.requestDay = requestDays[0];
        console.log("requestDays", requestDays);
        this.workout = data.workout;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error", error);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error loading workout!";
      }
    } else {
      for (let days = 14; days > -1; days--) {
        let day = moment().add(days, "d");
        requestDays.push({
          displayDate: day.format("MMM Do (dddd)"),
          formattedDate: day.format("YYYY-MM-DD"),
        });
      }
      this.requestDays = requestDays.reverse();
      this.loading = false;
    }
    this.availableWorkouts = {
      1: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(1))),
        "createDate"
      ),
      2: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(2))),
        "createDate"
      ),
      3: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(3))),
        "createDate"
      ),
      4: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(4))),
        "createDate"
      ),
      5: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(5))),
        "createDate"
      ),
      6: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(6))),
        "createDate"
      ),
      7: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(7))),
        "createDate"
      ),
      8: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(8))),
        "createDate"
      ),
      9: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(9))),
        "createDate"
      ),
      10: sortBy(
        this.exercises.filter((e) => e.rounds.includes(Number(10))),
        "createDate"
      ),
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async randomizeFromPrevious() {
      this.randomizeLoader = true;
      // random date between 30 days ago and 180 days ago
      // const randomDate = moment().subtract((Math.floor(Math.random() * 150)+20), 'd');
      const randomDate = moment().subtract(
        Math.floor(Math.random() * 31) + 10,
        "d"
      );
      console.log("date", randomDate.format("YYYY-MM-DD"));
      const res = await fb.db
        .collection("scheduledWorkouts")
        .doc(randomDate.format("YYYY-MM-DD"))
        .get();
      const data = { id: res.id, ...res.data() };
      console.log("data.id", data.id);
      if (data.workout) {
        this.workout = data.workout;
      }
      this.randomizeLoader = false;
    },
    async randomize() {
      this.randomizeLoader = true;
      // randomize workout based on options
      const keys = Object.keys(this.workout);
      // eslint-disable-next-line
      debugger;
      keys.forEach((k) => {
        this.workout[k] = [];
        const randomExercise =
          this.availableWorkouts[k][
            Math.floor(Math.random() * this.availableWorkouts[k].length)
          ];
        const randomExercise2 =
          this.availableWorkouts[k][
            Math.floor(Math.random() * this.availableWorkouts[k].length)
          ];
        this.workout[k].push(randomExercise);
        this.workout[k][0].instructions =
          this.duration[Math.floor(Math.random() * this.duration.length)];
        this.workout[k].push(randomExercise2);
        this.workout[k][1].instructions =
          this.duration[Math.floor(Math.random() * this.duration.length)];
      });

      this.randomizeLoader = false;
    },
    filterExercises(round) {
      console.log(round);
      this.filteredExercises = this.exercises.filter((e) =>
        e.rounds.includes(Number(round))
      );
    },
    async saveWorkout() {
      try {
        // Clean up the workout object (if necessary)
        const keys = Object.keys(this.workout);
        keys.forEach((k) => {
          this.workout[k] = this.workout[k].filter((a) => a);
        });

        const docRef = fb.db
          .collection("scheduledWorkouts")
          .doc(this.requestDay.formattedDate);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          // Document exists, so only update `lastUpdatedBy`
          await docRef.set(
            {
              workout: this.workout,
              lastUpdatedBy: this.user,
              date: this.requestDay.formattedDate,
            },
            { merge: true }
          );
        } else {
          // Document doesn't exist, set both `createdBy` and `lastUpdatedBy`
          await docRef.set(
            {
              workout: this.workout,
              createdBy: this.user,
              lastUpdatedBy: this.user,
              date: this.requestDay.formattedDate,
            },
            { merge: true }
          );
        }

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved workout!";
      } catch (error) {
        console.log("error", error);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving workout!";
      }
    },
  },
};
</script>
