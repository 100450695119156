<template>
  <v-container fluid style="max-width: 1680px">
    <v-dialog v-model="punchInDialog" max-width="400">
      <v-card v-if="currentEmployee">
        <v-toolbar dark color="primary">
          <h2>
            {{ `${currentEmployee.firstName} ${currentEmployee.lastName}` }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              punchInDialog = false;
              currentEmployee = null;
            "
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mt-4">
          <h2 class="mt-4">
            <v-btn
              color="success"
              class="mr-4"
              x-large
              block
              @click="punch('in')"
              >Punch In</v-btn
            >
          </h2>
          <br />
          <div class="text-center">or</div>

          <h2 class="mt-4">
            <v-btn
              color="success"
              class="mr-4"
              x-large
              block
              @click="punch('out')"
              >Punch Out</v-btn
            >
          </h2>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="headline"
        ><v-icon color="primary" large>schedule</v-icon> Punch-in</v-col
      >
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <v-autocomplete
          outlined
          clearable
          label="Search Name"
          :items="employees"
          prepend-inner-icon="search"
          item-text="fullName"
          v-model="autocompleteValue"
          autocomplete
          return-object
          :loading="loading"
          @change="onChangeAutocomplete"
        ></v-autocomplete>

        <!-- upcoming trials -->
        <v-card>
          <v-card-title class="grey lighten-3 pa-2"
            >Upcoming Trial Workouts
          </v-card-title>
          <v-card-text>
            <v-simple-table dense class="mt-2" v-if="!loadingTrials">
              <template v-slot:default>
                <thead>
                  <th class="px-0 pr-1 text-left">Title</th>
                  <th class="px-0 pr-1 text-left">Date</th>
                  <th class="px-0 pr-1 text-left">Time</th>
                </thead>
                <tbody>
                  <tr v-for="event of events" :key="event.id">
                    <td class="px-0 pr-1">
                      {{ event.title.split(" - ")[0] }}
                    </td>
                    <td class="px-0 pr-1">
                      {{ event.startTime | moment("dddd - MMM Do") }}
                    </td>
                    <td class="px-0 pr-1">
                      {{ event.startTime | moment("h:mm A") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              v-else
              indeterminate
              color="primary"
              class="mt-4"
            />
          </v-card-text>
        </v-card>
        <br />
        <v-card v-if="user.admin">
          <v-card-title class="grey lighten-3 pa-2"
            >Recent Punch-ins
          </v-card-title>
          <v-card-text>
            <v-simple-table dense class="mt-2" v-if="!loading">
              <template v-slot:default>
                <thead>
                  <th class="px-0 pr-1 text-left">Name</th>
                  <th class="px-0 pr-1 text-left">Date</th>
                  <th class="px-0 pr-1 text-left">Time</th>
                </thead>
                <tbody>
                  <tr v-for="punch of recentPunchins" :key="punch.id">
                    <td class="px-0 pr-1">
                      {{ punch.name }}
                    </td>
                    <td class="px-0 pr-1">
                      {{ punch.time.toDate() | moment("dddd - MMM Do") }}
                    </td>
                    <td class="px-0 pr-1">
                      {{ punch.time.toDate() | moment("h:mm A") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              v-else
              indeterminate
              color="primary"
              class="mt-4"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      top
      class="snackbar-top-offset"
    >
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      loadingTrials: true,
      snack: false,
      snackColor: "",
      snackText: "",
      autocompleteValue: null,
      punchInDialog: false,
      currentEmployee: null,
      employees: [],
      recentPunchins: [],
      unsubscribeRecentPunches: null,
    };
  },
  async created() {
    this.getEmployees();
    this.upComingEvents();
    this.getRecentPunches();
  },
  computed: {
    ...mapGetters(["user"]),
    capitalizedFirstName() {
      return (
        this.currentEmployee.firstName.charAt(0).toUpperCase() +
        this.currentEmployee.firstName.slice(1)
      );
    },
  },
  methods: {
    async getEmployees() {
      try {
        const contactsRef = await fb.db.collection("employees").get();
        const employees = [];
        contactsRef.forEach((doc) => {
          employees.push({
            id: doc.id,
            fullName: `${doc.data().firstName} ${doc.data().lastName}`,
            ...doc.data(),
          });
        });
        this.employees = employees;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error.message);
        this.snackColor = "error";
        this.snackText = "Error fetching members";
        this.snack = true;
      }
    },
    async onChangeAutocomplete() {
      if (this.autocompleteValue) {
        this.currentEmployee = this.autocompleteValue;
        this.punchInDialog = true;
      }
    },
    async punch(direction) {
      try {
        if (!this.currentEmployee) {
          throw new Error("No employee selected.");
        }

        const punchInData = {
          userId: this.currentEmployee.id,
          name: `${this.currentEmployee.firstName} ${this.currentEmployee.lastName}`,
          time: fb.timestamp,
          locationID: this.currentEmployee.locationId,
          type: direction,
        };

        // Add the punch-in data to the 'punchins' collection
        await fb.db.collection("punchins").add(punchInData);

        // Show success message
        this.snack = true;
        this.snackColor = "success";
        this.snackText = `Successfully Punched ${direction}`;
        this.punchInDialog = false;
        this.autocompleteValue = null;
      } catch (error) {
        // Handle errors gracefully
        console.error(error.message);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error during punch-in!";
      }
    },
    async upComingEvents() {
      // get events from oncall functions
      try {
        const events = await fb.functions.httpsCallable("getUpcomingEvents")();
        this.events = events.data;
        console.log("events", events);

        this.loadingTrials = false;
      } catch (error) {
        this.loadingTrials = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error fetching events";
      }
    },
    getRecentPunches() {
      try {
        this.unsubscribeRecentPunches = fb.db
          .collection("punchins")
          .orderBy("time", "desc")
          .limit(5)
          .onSnapshot((snapshot) => {
            const recentPunchins = [];
            snapshot.forEach((doc) => {
              recentPunchins.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.log("recentPunchins", recentPunchins);

            this.recentPunchins = recentPunchins;
          });
      } catch (error) {
        console.error(error.message);
        this.snackColor = "error";
        this.snackText = "Error fetching recent punchin data";
        this.snack = true;
      }
    },
  },
  beforeDestroy() {
    if (this.unsubscribeRecentPunches) {
      this.unsubscribeRecentPunches();
    }
  },
};
</script>
/* Scoped CSS */
<style scoped>
.snackbar-top-offset {
  top: 50px !important; /* Adjust this value as needed */
}
</style>
