<template>
  <div :style="{ backgroundImage: 'url(' + image + ')' }" class="login-bg">
    <v-main class="pt-4 mt-4">
      <v-container fluid fill-height grid-list-md>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 signup-card">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div>
                      <div class="headline text-center">
                        <img
          src="https://www.nolabelsboxing.com/assets/svg/logos/logo.svg"
          width="200"
          class="d-inline-block ml-3"
          @click="goHome"
          style="cursor: pointer"
        />
                      </div>
                      <!-- <h2 class="text-center font-weight-regular pt-0 mt-0">
                        SYSTEM BUILDER
                      </h2> -->
                    </div>

                    <br />
                    <v-form>
                      <v-text-field
                        prepend-icon="person"
                        name="email"
                        label="Email"
                        type="email"
                        v-model="email"
                      ></v-text-field>
                      <v-text-field
                        prepend-icon="lock"
                        name="password"
                        label="Password"
                        id="password"
                        type="password"
                        v-model="password"
                      ></v-text-field>
                      <p class="text-xs-right">
                        <a @click="forgotPassword">Forgot your password?</a>
                      </p>
                      <v-btn @click="login" block dark color="primary" large
                        >Sign In</v-btn
                      >
                      <br />
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import fb from "@/main";
import image from "@/assets/img/bg.jpg";
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      image,
    };
  },
  methods: {
    login: async function (e) {
      e.preventDefault();
      try {
        const userRes = await fb.auth.signInWithEmailAndPassword(
          this.email,
          this.password
        );
        const doc = await fb.db.collection("users").doc(userRes.user.uid).get();
        this.$store.commit("cacheUser", doc.data());
        this.$router.push("/");
      } catch (e) {
        alert(e.message);
        console.log(e);
      }
    },
    forgotPassword: function () {
      const email = prompt("Enter your email to request a password reset.");
      if (email) {
        fb.auth
          .sendPasswordResetEmail(email)
          .then(function () {
            alert(
              "Email Sent with password reset link. Check your email inbox."
            );
          })
          .catch(function () {
            console.log("error sending password reset email.");
          });
      }
    },
    async goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
